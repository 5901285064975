import * as React from "react"
import styled from "styled-components"

import { size, trumanColors } from "../styles/styles"

export const MainContainer = () => {
  const ContainerDiv = styled.div`
    box-size: border-box;
  `
  const FormContainer = styled.div`
    filter: drop-shadow(0 0 0.75rem ${trumanColors.grey});
    box-sizing: border-box;
    border-radius: 5px;
    width: 55%;
    margin-top: 10vh;
    padding: 10px 20px;
    border: 1px solid ${trumanColors.grey};
    background-color: ${trumanColors.white};
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: ${size.small}) {
      width: 75%;
    }
    @media only screen and (max-width: ${size.mobile}) {
      width: 95%;
    }
    p {
      text-align: center;
    }
    h2 {
      text-align: center;
      font-size: 2rem;
    }
  `
  const FormItems = styled.p`
    margin-top: 5px;
    div.select-div {
      margin-top: 5px;
    }
  `
  const NetlifyForm = styled.form`
    p.hidden {
      visibility: hidden;
    }
  `
  const Label = styled.label``
  const Input = styled.input`
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    box-sizing: border-box;
    outline: 0 !important;
    padding: 0 10px;
    margin-top: 5px;
    width: 100%;
  `

  const options = [
    { value: "booking", label: "About booking Truman" },
    { value: "website", label: "When the website is ready" },
    { value: "merchandise", label: "About merchandise" },
    { value: "other", label: "Other" },
  ]

  return (
    <ContainerDiv>
      <FormContainer>
        <h2>&#128679; Our site is under construction &#128679;</h2>
        <p>Please fill out the form below for inquiries about Truman.</p>
        <NetlifyForm
          name="contact"
          netlify-honeypot="bot-field"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="contact" value="contact" />
          <p className="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <FormItems>
            <Label>Name:</Label>
            <br />
            <Input type="text" name="name" />
          </FormItems>
          <FormItems>
            <Label>Email:</Label>
            <br />
            <Input type="email" name="email" />
          </FormItems>
          <FormItems>
            <Label>Contact me about:</Label>
            <br />
            <Input type="textarea" name="about" />
          </FormItems>
          <FormItems>
            <button type="submit">Send</button>
          </FormItems>
        </NetlifyForm>
      </FormContainer>
    </ContainerDiv>
  )
}
