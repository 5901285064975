import * as React from "react"
import "../styles/resets.css"
import { NavBar } from "../components/NavBar"
import { MainContainer } from "../components/UnderContruction"

export default function Home() {
  return (
    <div>
      <NavBar />
      <MainContainer />
    </div>
  )
}
